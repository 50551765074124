import './ExploreContainer.css';

interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="container">
      <strong>{name}</strong>
      <p>Explore</p>
    </div>
    
  );

  alert("Hello! I am an alert box!!");
};

export default ExploreContainer;
