import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonList, IonInput, IonItemDivider } from '@ionic/react';
import {IonImg } from '@ionic/react';
import { pin, wifi, wine, warning, walk, navigateCircleOutline, phonePortraitOutline, desktopOutline, mailOutline, logoGooglePlaystore } from 'ionicons/icons';
import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';

const Tab2: React.FC = () => {


  const [text, setText] = useState<string>();
  const [number, setNumber] = useState<number>();

  return (
    <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Acerca de</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <IonCard>
          <IonCardHeader>
           
            <IonCardTitle><IonIcon icon={navigateCircleOutline} /> WSQuick</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Realiza envio de mensajes sin agregar el numero de destino como contacto en el dispositivo. Esta app trabaja por medio de la API del aplicativo de mensajeria<br></br><br></br>

          </IonCardContent>
      </IonCard>



      <IonCard>
          <IonCardContent>
              Esta es una version web que funciona desde el navegador de tu dispositivo. <br></br><br></br>
              Disponible app para instalar en dispositivos android en: <br></br>
              <IonCardTitle><a href="https://play.google.com/store/apps/details?id=w.marcano.net.ve" target="_blank"><IonIcon icon={logoGooglePlaystore} /> Google Play </a> </IonCardTitle>
          
          </IonCardContent>
      </IonCard>



      <IonCard>
          <IonCardContent>
                Copyright 2022 <br></br>
                Jose Miguel Marcano <br></br>
                <IonCardTitle><IonIcon icon={mailOutline} /></IonCardTitle>
                <a href="mailto:wsquick@marcano.net.ve">wsquick@marcano.net.ve</a><br></br><br></br>
          
          </IonCardContent>
      </IonCard>

         
        
        
        
        
          


          

          


    

      

      
    </IonContent>
  </IonPage>
  );
};

export default Tab2;
