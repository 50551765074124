import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonList, IonInput, IonItemDivider } from '@ionic/react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import { chatboxEllipsesOutline, storefrontOutline, navigateOutline, paperPlane } from 'ionicons/icons';
import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';

const Tab1: React.FC = () => {

  const [numero, setNumero] = useState();
  const [text, setText] = useState<string>();
  const [number, setNumber] = useState<number>();
  const estiloDiv = {color: 'yellow'}

  const options = {
    cssClass: 'my-custom-interface'
  };
  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle><IonIcon icon={paperPlane} /> WSQuick</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        




        <IonCard>
        <IonCardContent>
          Permite abrir una conversacion de Whatsapp con un numero telefónico sin guardar como contacto en el dispositivo<br></br>
        </IonCardContent>
        </IonCard>

       

        <IonCard>
           {/* <IonCardHeader>
           <IonCardSubtitle>Card Subtitle</IonCardSubtitle>*/}
           {/*<IonCardTitle>Como?</IonCardTitle>
        </IonCardHeader> */}

        <IonCardContent>
          Introduzca el número comenzando por el codigo de pais SIN AGREGAR el simbolo + al inicio <br></br>
          No agregar ceros al inicio del codigo de pais <br></br>
          <span>Ejemplo: &nbsp; 57305XXXXXXX &nbsp; &nbsp; |  &nbsp; &nbsp; USA: 1786XXXXXXX</span><br></br>
          Luego pulsa el boton ENVIAR

        </IonCardContent>
        </IonCard>

          
          
       
        <IonItem>
            <IonLabel position="floating">Cod pais + Numero Telefono</IonLabel>
            <IonInput type='number' value={numero} onIonChange={ (e: any)=> setNumero(e.target.value)} ></IonInput>
          </IonItem>

          <IonButton color="primary" expand="block" onClick={ ()=> window.open("https://api.whatsapp.com/send?phone=" + numero + "", "_blank") }>Enviar Mensaje<IonIcon slot="end" icon={navigateOutline} /></IonButton>
        
        
          

          
        
          
       
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
